import { forwardRef } from 'react';
import {
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  InputRightElementProps,
  Text,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CopyComponent } from './copy-component';

export const InputClipboard = forwardRef<
  HTMLInputElement,
  InputProps & {
    label?: string;
    value: string;
    canCopy?: boolean;
    copyIconProps?: InputRightElementProps;
  }
>(({ label, value, canCopy = true, copyIconProps, ...rest }, ref) => {
  return (
    <InputGroup>
      <Input
        minH={82}
        ref={ref}
        isReadOnly
        fontSize={18}
        fontWeight={500}
        borderRadius={0}
        textStyle={TextStyles.BodyText16Medium}
        variant="floating"
        value={value}
        {...rest}
      />
      {label && (
        <FormLabel variant="floating">
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
            {label}
          </Text>
        </FormLabel>
      )}
      {canCopy && (
        <InputRightElement padding="29px 16px 0 29px" {...copyIconProps}>
          <CopyComponent value={value} />
        </InputRightElement>
      )}
    </InputGroup>
  );
});
